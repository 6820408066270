export const technologyOptions = [
  { key: 'html', category: 'frontend', text: 'Html', value: 'html' },
  { key: 'css', category: 'frontend', text: 'Css', value: 'css' },
  { key: 'javascript', category: 'frontend', text: 'Javascript', value: 'javascript' },
  { key: 'jQuery', category: 'frontend', text: 'Jquery', value: 'jQuery' },
  { key: 'reactjs', category: 'frontend', text: 'Reactjs', value: 'reactjs' },
  { key: 'bootstrap', category: 'frontend', text: 'Bootstrap', value: 'bootstrap' },
  { key: 'sass', category: 'frontend', text: 'Sass', value: 'sass' },
  { key: 'vuejs', category: 'frontend', text: 'Vuejs', value: 'vuejs' },
  { key: 'angular', category: 'frontend', text: 'Angular', value: 'angular' },
  { key: 'figma', category: 'frontend', text: 'Figma', value: 'figma' },
  { key: 'photoshop', category: 'frontend', text: 'Photoshop', value: 'photoshop' },
  { key: 'adobeXD', category: 'frontend', text: 'Adobe Xd', value: 'adobeXD' },
  { key: 'wordpress', category: 'frontend', text: 'Wordpress', value: 'wordpress' },
  { key: 'nodejs', category: 'backend', text: 'Nodejs', value: 'nodejs' },
  { key: 'python', category: 'backend', text: 'Python', value: 'python' },
  { key: 'ruby', category: 'backend', text: 'Ruby', value: 'ruby' },
  { key: 'java', category: 'backend', text: 'Java', value: 'java' },
  { key: 'spring', category: 'backend', text: 'Spring', value: 'spring' },
  { key: 'typescript', category: 'backend', text: 'Typescript', value: 'typescript' },
  { key: 'cSharp', category: 'backend', text: 'C#', value: 'c#' },
  { key: 'c++', category: 'backend', text: 'C++', value: 'c++' },
  { key: 'php', category: 'backend', text: 'Php', value: 'php' },
  { key: 'perl', category: 'backend', text: 'Perl', value: 'perl' },
  { key: 'scala', category: 'backend', text: 'Scala', value: 'scala' },
  { key: 'postgreSql', category: 'bd', text: 'Postgre Sql', value: 'postgreSql' },
  { key: 'sql', category: 'bd', text: 'Sql', value: 'sql' },
  { key: 'nosql', category: 'bd', text: 'noSql', value: 'nosql' },
  { key: 'mariadb', category: 'bd', text: 'Mariadb', value: 'mariadb' },
  { key: 'firebase', category: 'bd', text: 'Firebase', value: 'firebase' },
  { key: 'mongodb', category: 'bd', text: 'Mongodb', value: 'mongodb' },
  { key: 'mySql', category: 'bd', text: 'mySql', value: 'mySql' },
  { key: 'git', category: 'basicKnowledge', text: 'Git', value: 'git' },
  { key: 'github', category: 'basicKnowledge', text: 'Github', value: 'github' },
  { key: 'gitlab', category: 'basicKnowledge', text: 'Gitlab', value: 'gitlab' },
  { key: 'flutter', category: 'mobile', text: 'Flutter', value: 'flutter' },
  { key: 'reactNative', category: 'mobile', text: 'React Native', value: 'react native' },
  { key: 'android', category: 'mobile', text: 'Android', value: 'android' },
  { key: 'kotlin', category: 'mobile', text: 'Kotlin', value: 'kotlin' },
  { key: 'swift', category: 'mobile', text: 'Swift', value: 'swift' },
  { key: 'selenium', category: 'test', text: 'Selenium', value: 'selenium' },
  { key: 'cypress', category: 'test', text: 'Cypress', value: 'cypress' },
  { key: 'insominia', category: 'test', text: 'Insominia', value: 'insominia' },
  { key: 'bkeeper', category: 'test', text: 'Bkeeper', value: 'bkeeper' },
  { key: 'postman', category: 'test', text: 'Postman', value: 'postman' },
  
];

export const admSkills = [
  { key: 'admTec', category: 'adm', text: 'Técnico em Administração', value: 'Técnico em Administração' },
  { key: 'office', category: 'adm', text: 'Pacote Office', value: 'Pacote Office' },
  { key: 'powerbi', category: 'adm', text: 'PowerBi', value: 'PowerBi' },
];