export const categoryOptions = [
  {
    key: "option0",
    text: "Selecione",
    value: "",
  },
  {
    key: "option1",
    text: "Tecnologia",
    value: "Tecnologia",
  },
  {
    key: "option2",
    text: "Administração",
    value: "Administração",
  },
]