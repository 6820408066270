export const educationOptions =
  [
    {
      key: "fundamental",
      text: "Ensino Fundamental",
      value: "Ensino Fundamental",
    },
    { key: "medio", 
      text: "Ensino Médio", 
      value: "Ensino Médio" },
    {
      key: "superior",
      text: "Ensino Superior",
      value: "Ensino Superior",
    },
    {
      key: "pos",
      text: "Pós Graduação",
      value: "Pós Graduação",
    },
    { key: "mestrado",
      text: "Mestrado", 
      value: "Mestrado" },
    {
      key: "doutorado",
      text: "Doutorado",
      value: "Doutorado",
    },
  ]