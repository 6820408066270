import "./App.css";
import Home from "./pages/Home";
import AddEditUser from "./pages/AddEditUser";
import Signin from "./pages/Signin";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Signin />} />

          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Navbar />
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add"
            element={
              <ProtectedRoute>
                <Navbar />
                <AddEditUser />
              </ProtectedRoute>
            }
          />

          <Route
            path="/update/:id"
            element={
              <ProtectedRoute>
                <Navbar />
                <AddEditUser />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
