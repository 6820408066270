export const tecJobOptions = [
  {
    key: "default",
    text: "Selecione",
    value: "",
  },
  {
    key: "frontend",
    text: "Desenvolvedor Front-end",
    value: "Desenvolvedor Front-end",
  },
  {
    key: "backend",
    text: "Desenvolvedor Back-end",
    value: "Desenvolvedor Back-end",
  },
  {
    key: "fullstack",
    text: "Desenvolvedor Full-stack",
    value: "Desenvolvedor Full-stack",
  },
  {
    key: "devJava",
    text: "Desenvolvedor Java",
    value: "Desenvolvedor Java",
  },
  {
    key: "devMobile",
    text: "Desenvolvedor Mobile",
    value: "Desenvolvedor Mobile",
  },
]

