import React from "react";
import logo from "../assets/logo.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Menu, Container, Button, Image } from "semantic-ui-react";
import { UserAuth } from "../context/AuthContext";

const Navbar = () => {
  const { logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };

  const location = useLocation();
  
  return (
    <Menu
      inverted
      borderless
      style={{ padding: "0.3rem", marginBottom: "2rem" }}
      attached
      stackable
    >
      <Container>
        <Menu.Item>
          <Link to="/home">
            <Image size="mini" src={logo} alt="logo" />
          </Link>
        </Menu.Item>

        <Menu.Item>
          <h2>Recruiter</h2>
        </Menu.Item>
      </Container>
      <Menu.Item position="right" >
      {location.pathname === "/home" ?(
              <Button
              size="mini"
              inverted
              color="orange"
              onClick={() => navigate("/add")}
            >
              Adicionar Candidato
            </Button>
            ) : (
              <Button
          size="mini"
          inverted
          color="orange"
          onClick={() => navigate("/home")}
        >
          Voltar
        </Button>
            )}
      </Menu.Item>

      <Menu.Item position="right" >
        <Button size="mini" inverted color="orange" onClick={handleLogout}>
          Sair
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default Navbar;
