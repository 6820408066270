import React from 'react'
import { Loader } from 'semantic-ui-react';

const Spinner = () => {
  return (
    <>
      <Loader active inline="centered" size="huge" />
    </>
  )
}

export default Spinner