import React from "react";
import { Modal, Header, Button } from "semantic-ui-react";


const ModalComp = ({
  open,
  setOpen,
  pdfFile,
  name,
  info,
  email,
  contact,
  id,
  handleDelete,
  skills,
    education,
    github,
    linkedin,
    job,
    category,
    city,
    state,
    age,
    gender,
}) => {

  const calculateAge = (birthday) =>{
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getFullYear() - 1970);
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Detalhes do Funcionário</Modal.Header>
      <Modal.Content>
        <p><a href={pdfFile} target="#">Currículo</a></p>
        <Modal.Description>
          <Header>{name}</Header>
          <p><b>Sexo: </b>{gender}</p>
          <p><b>Idade: </b>{!age ? "Não informado" : calculateAge(new Date(age)) + " anos"}</p>
          <p><b>Contato: </b>{contact}</p>
          <p><b>Email: </b>{email}</p>
          <p><b>Linkedin: </b><a href={linkedin} target="_blank">{linkedin}</a></p>
          <p><b>Github: </b><a href={github} target="_blank">{github}</a></p>
          <p><b>Educação: </b>{education}</p>
          <p><b>Categoria: </b>{category}</p>
          <p><b>Cargo: </b>{job}</p>
          <p><b>Skills: </b>{skills.join(", ")}</p>
          <p><b>Obs: </b>{info}</p>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
      <Modal.Content>
        <Button inverted color="orange" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          inverted
          color="orange"
          onClick={() => handleDelete(id)}
        >
          Deletar
        </Button>
        </Modal.Content>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalComp;
