import React, { useEffect, useState } from "react";
import { db } from "../libs/firebase";
import {
  Button,
  Grid,
  Container,
  Menu,
  Form,
  Dropdown,
  Pagination,
  Icon,
  Table,
  Header,
  Select,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import ModalComp from "../components/ModalComp";
import Spinner from "../components/Spinner";
import {
  technologyOptions,
  admSkills,
} from "../services/options/technologyOptions";
import { tecJobOptions } from "../services/options/tecJobOptions";
import { categoryOptions } from "../services/options/categoryOptions";

const Home = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchJob, setSearchJob] = useState("");
  const [stateOptions, setStateOptions] = useState(tecJobOptions);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [admSkillsOptions, setAdmSkillsOptions] = useState(admSkills);
  const [tecSkillsOptions, setTecSkillsOptions] = useState(technologyOptions);


  const navigate = useNavigate();

  // PAGINATION
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(users.length / itemsPerPage);

  const initialState = {
    name: "",
    email: "",
    info: "",
    contact: "",
    education: "",
    linkedin: "",
    github: "",
    job: "",
    skills: [],
    cvvUrl: "",
    category: "",
    city: "",
    state: "",
    age: "",
    gender: "",
  };

  const [data, setData] = useState(initialState);
  const {
    name,
    email,
    info,
    contact,
    education,
    github,
    linkedin,
    job,
    skills,
    cvvUrl,
    category,
    city,
    state,
    age,
    gender,
  } = data;

  // GET DATA FROM FIREBASE

  useEffect(() => {
    setLoading(true);

    const unsub = onSnapshot(
      collection(db, "users"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setUsers(list);
        setLoading(false);
      },

      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  if (loading) {
    return <Spinner />;
  }

  // MODAL

  const handleModal = (item) => {
    setOpen(true);
    setUser(item);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Tem certeza que deseja deletar o usuário?")) {
      try {
        setOpen(false);
        await deleteDoc(doc(db, "users", id));
        setUsers(users.filter((user) => user.id !== id));
      } catch (err) {
        console.log(err);
      }
    }
  };

  // SEARCH EMPLOYEE AND FILTERS

  const handleSearchName = (val) => {
    if (search === "") {
      return val;
    } else if (val.name.toLowerCase().includes(search.toLocaleLowerCase())) {
      return val;
    }
  };

  const handleSearchCategory = (val) => {
    if (searchCategory === "") {
      return val;
    } else if (
      val.category.toLowerCase().includes(searchCategory.toLocaleLowerCase())
    ) {
      return val;
    }
  };

  const handleSearchJob = (val) => {
    if (searchJob === "") {
      return val;
    } else if (val.job.toLowerCase().includes(searchJob.toLocaleLowerCase())) {
      return val;
    }
  };

  const handleFilter = (val) => {
    if (selectedSkills.length === 0) {
      return val;
    } else if (
      val.skills.length &&
      selectedSkills.every((skill) => val.skills.includes(skill))
    ) {
      return val;
    } else {
      return null;
    }
  };

  // ITEMS COUNTER

  const ItemCounter = ({ users, search }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      setCount(
        users
          .filter(handleSearchName)
          .filter(handleSearchCategory)
          .filter(handleSearchJob)
          .filter(handleFilter).length
      );
    }, [search]);

    return <span>{count}</span>;
  };

  // PAGINATION PAGE CHANGE

  const handlePageChange = (e, { activePage }) => {
    if (activePage <= totalPages) {
      setCurrentPage(activePage);
    }
  };

  // FUNCTION ADD SKILLS ITEM

  const handleAddItem = (e, { value }) => {
    setAdmSkillsOptions(...admSkillsOptions,[
      { key: value, text: value, value },
    ]);
    setTecSkillsOptions([
      ...tecSkillsOptions,
      { key: value, text: value, value },
      
    ]);

    // if (data.category === "Tecnologia") {
    //   setTecSkillsOptions([
    //     ...tecSkillsOptions,
    //     { key: value, text: value, value },
    //   ]);
    // } else {
    //   setAdmSkillsOptions([
    //     ...admSkillsOptions,
    //     { key: value, text: value, value },
    //   ]);
    // }
  };

  const handleAddition = (e, { value }) => {
    setStateOptions((prevState) => [...prevState, { text: value, value }]);
  };

  return (
    <>
      <Grid columns={2} style={{ padding: "1em" }}>
        <Container>
          <Form inverted>
            <Menu attached="top" compact inverted widths={1}>
              <Grid columns="equal" container stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Input
                      label="Nome"
                      placeholder="Nome"
                      onChange={(e) => setSearch(e.target.value)}
                      autoFocus
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field>
                      <label>Categoria</label>
                      <Dropdown
                        placeholder="Categoria"
                        fluid
                        selection
                        autoFocus
                        options={categoryOptions}
                        onChange={(e, { value }) => setSearchCategory(value)}
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field>
                      <label>Cargo</label>
                      {searchCategory == "Administração" ? (
                        <Form.Input
                          placeholder="Cargo"
                          onChange={(e) => setSearchJob(e.target.value)}
                          autoFocus
                        />
                      ) : (
                        <Dropdown
                          options={stateOptions}
                          placeholder="Informe o Cargo"
                          search
                          selection
                          fluid
                          allowAdditions
                          value={data.job}
                          onAddItem={handleAddition}
                          onChange={(e, { value }) => {
                            setSearchJob(value);
                            setData({ ...data, job: value, text: value });
                          }}
                        />
                      )}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>
                      <label>Skills</label>
                      {searchCategory == "Administração" ? (
                        <Dropdown
                          placeholder="Selecione as opções"
                          fluid
                          multiple
                          search
                          selection
                          allowAdditions
                          options={admSkillsOptions}
                          onAddItem={handleAddItem}
                          onChange={(e, data) => {
                            setSelectedSkills(data.value);
                          }}
                        />
                      ) : (
                        <Dropdown
                          placeholder="Selecione as opções"
                          fluid
                          multiple
                          search
                          selection
                          allowAdditions
                          options={tecSkillsOptions}
                          onAddItem={handleAddItem}
                          onChange={(e, data) => {
                            setSelectedSkills(data.value);
                          }}
                        />
                      )}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Menu>
          </Form>
          <Menu attached="top" compact inverted widths={1}>
            <Menu.Item>
              <span>
                Total de Candidatos:{" "}
                {<ItemCounter users={users} search={search} />}
              </span>
            </Menu.Item>
          </Menu>
          <Table attached inverted selectable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Categoria</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Cargo</Table.HeaderCell>
                <Table.HeaderCell>Currículo</Table.HeaderCell>
                <Table.HeaderCell>Atualizar / Visualizar</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {users
              .filter(handleSearchName)
              .filter(handleSearchCategory)
              .filter(handleSearchJob)
              .filter(handleFilter)
              .slice(startIndex, endIndex)
              .map((item) => (
                <Table.Body key={item.id}>
                  <Table.Row>
                    <Table.Cell>{item.category}</Table.Cell>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{item.job}</Table.Cell>
                    <Table.Cell>
                      <Header style={{ padding: "10px 0" }}>
                        <a href={item.pdfFile} target="#" className="pdfLink">
                          <Icon name="file pdf" size="huge" />
                        </a>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        <Button
                          style={{ marginBottom: ".5rem" }}
                          inverted
                          color="orange"
                          onClick={() => navigate(`/update/${item.id}`)}
                        >
                          Atualizar
                        </Button>

                        <Button
                          inverted
                          color="orange"
                          onClick={() => handleModal(item)}
                        >
                          Visualizar
                        </Button>
                        {open && (
                          <ModalComp
                            open={open}
                            setOpen={setOpen}
                            handleDelete={handleDelete}
                            {...user}
                          />
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}
          </Table>

          <Grid.Row>
            <Grid.Column>
              <Pagination
                inverted
                style={{ margin: "1rem auto" }}
                activePage={currentPage}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                siblingRange={0}
                secondary
              />
            </Grid.Column>
          </Grid.Row>
        </Container>
      </Grid>
    </>
  );
};

export default Home;
