import React, { useState, useEffect } from "react";
import { Button, Form, Grid, Dropdown, Input } from "semantic-ui-react";
import { storage, db } from "../libs/firebase";
import { useParams, useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  serverTimestamp,
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

//  OPTIONS
import {
  technologyOptions,
  admSkills,
} from "../services/options/technologyOptions";
import { tecJobOptions } from "../services/options/tecJobOptions";
import { educationOptions } from "../services/options/educationOptions";
import { admJobOptions } from "../services/options/admJobOptions";

const initialState = {
  name: "",
  email: "",
  info: "",
  contact: "",
  education: "",
  linkedin: "",
  github: "",
  job: "",
  skills: [],
  cvvUrl: "",
  category: "",
  city: "",
  state: "",
  age: "",
  gender: "",
};

export const AddEditUser = () => {
  const [data, setData] = useState(initialState);
  const {
    name,
    email,
    info,
    contact,
    education,
    github,
    linkedin,
    job,
    skills,
    cvvUrl,
    category,
    city,
    state,
    age,
    gender,
  } = data;

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [admSkillsOptions, setAdmSkillsOptions] = useState(admSkills);
  const [tecSkillsOptions, setTecSkillsOptions] = useState(technologyOptions);
  // const [jobOptions, setJobOptions] = useState(tecJobOptions)

  const [stateOptions, setStateOptions] = useState(tecJobOptions);
  // const [currentValue, setCurrentValue] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSingleUser();
  }, [id]);

  const getSingleUser = async () => {
    const docRef = doc(db, "users", id);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  // UPLOAD PDF FILE AND GET URLFILE

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is pause");
              break;
            case "running":
              console.log("Upload is Running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          file.type === "application/pdf"
            ? getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setData((prev) => ({ ...prev, pdfFile: downloadURL }));
                console.log(downloadURL);
                console.log("file type: " + file.type);
              })
            : alert("Apenas documentos PDF são aceitos.");
        }
      );
    };

    file && uploadFile();
  }, [file]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = "Name is Required";
    }
    if (!email) {
      errors.email = "Email is Required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);
    setIsSubmit(true);
    if (!id) {
      try {
        await addDoc(collection(db, "users"), {
          ...data,
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await updateDoc(doc(db, "users", id), {
          ...data,
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        console.log(error);
      }
    }

    navigate("/home");
  };

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  // setData({ ...data, skills: value });
  // const handleChangeOpt = (e, { value }) => {
  //   let options = data.category !== "Tecnologia"
  //   ? admSkillsOptions
  //   : tecSkillsOptions;

  // // check if the value is not in the options array
  // if (!options.find(option => option.value === value)) {
  //   options = [...options, { key: value, text: value, value }];
  // }

  // setData({ ...data, skills: value });
  // setTecSkillsOptions(options);

  // };

  // const handleAddItem = (e, { value }) => {
  //   // setAdmSkillsOptions([
  //   //   { key: value, text: value, value },
  //   //   ...admSkillsOptions,
  //   // ]);
  //   // setTecSkillsOptions([
  //   //   { key: value, text: value, value },
  //   //   ...tecSkillsOptions,
  //   // ]);
  //   if (data.category === "Tecnologia") {
  //     setTecSkillsOptions([
  //       ...tecSkillsOptions,
  //       { key: value, text: value, value },
  //     ]);
  //   } else {
  //     setAdmSkillsOptions([
  //       ...admSkillsOptions,
  //       { key: value, text: value, value },
  //     ]);
  //   }
  // };

  const handleChangeOpt = (e, { value }) => {
    setData({ ...data, skills: value });

    // let options = data.category !== "Tecnologia" ? admSkillsOptions : tecSkillsOptions;
    // options = options.concat(value.filter(val => !options.find(option => option.value === val)));

    // if (data.category === "Tecnologia") {
    //   setTecSkillsOptions(options);
    // } else {
    //   setAdmSkillsOptions(options);
    // }

    //   let options = data.category !== "Tecnologia"
    //   ? admSkillsOptions
    //   : tecSkillsOptions;

    // // check if the value is not in the options array
    // if (!options.find(option => option.value === value)) {
    //   options = [...options, { key: value, text: value, value }];
    // }

    // setData({ ...data, skills: value });
    // setTecSkillsOptions(options);

    // -------------------

    // let options =
    //   data.category !== "Tecnologia" ? admSkillsOptions : tecSkillsOptions;

    // // check if the value is not in the options array
    // if (!options.find((option) => option.value === value)) {
    //   options = [...options, { key: value, text: value, value }];
    // }

    // setData({ ...data, skills: [...data.skills, value] });
    // setTecSkillsOptions(options);
  };

  const handleAddItem = (e, { value }) => {
    if (data.category === "Tecnologia") {
      setTecSkillsOptions([
        ...tecSkillsOptions,
        { key: value, text: value, value },
      ]);
    } else {
      setAdmSkillsOptions([
        ...admSkillsOptions,
        { key: value, text: value, value },
      ]);
    }
  };

  const handleAddition = (e, { value }) => {
    setStateOptions((prevState) => [{ text: value, value }, ...prevState]);
  };

  // const handleChangeJob = (e, { value }) => setCurrentValue(value);

  // const handleAddJob = (e, { value }) => {
  //   setJobOptions([{ key: value, text: value, value }, ...jobOptions]);
  //   console.log(jobOptions)
  // };

  return (
    <Form onSubmit={handleSubmit} inverted>
      <Grid container columns={"equal"} stackable>
        <Grid.Row>
          <Grid.Column>
            <Form.Input
              label="Nome"
              error={errors.name ? { content: errors.name } : null}
              placeholder="Nome"
              name="name"
              onChange={handleChange}
              value={name}
              autoFocus
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Email"
              error={errors.email ? { content: errors.email } : null}
              placeholder="Email"
              name="email"
              onChange={handleChange}
              value={email}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Contato"
              placeholder="Contato"
              name="contact"
              onChange={handleChange}
              value={contact}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Nascimento"
              placeholder="Nascimento"
              name="age"
              type="date"
              onChange={handleChange}
              max={eighteenYearsAgo.toISOString().substring(0, 10)}
              value={age}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form.Input
              label="Linkedin"
              placeholder="Linkedin"
              name="linkedin"
              onChange={handleChange}
              value={linkedin}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Input
              label="Github"
              placeholder="Github"
              name="github"
              onChange={handleChange}
              value={github}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label>Gênero</label>
              <Dropdown
                placeholder="Gênero"
                fluid
                selection
                options={[
                  { key: "m", text: "Masculino", value: "Masculino" },
                  { key: "f", text: "Feminino", value: "Feminino" },
                  { key: "o", text: "outro", value: "Outro" },
                ]}
                value={data.gender}
                onChange={(e, { value }) => setData({ ...data, gender: value })}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Field>
              <label>Formação</label>
              <Dropdown
                placeholder="Formação"
                fluid
                selection
                options={educationOptions}
                value={data.education}
                onChange={(e, { value }) =>
                  setData({ ...data, education: value })
                }
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <label>Categoria</label>
              <Dropdown
                placeholder="Categoria"
                fluid
                selection
                options={[
                  { key: "option1", text: "Tecnologia", value: "Tecnologia" },
                  {
                    key: "option2",
                    text: "Administração",
                    value: "Administração",
                  },
                ]}
                value={data.category}
                onChange={(e, { value }) =>
                  setData({ ...data, category: value })
                }
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <label>Cargo</label>
              {data.category === "Tecnologia" ? (
                <Dropdown
                  options={
                    stateOptions.find((option) => option.value === data.job)
                      ? stateOptions
                      : [...stateOptions, { text: data.job, value: data.job }]
                  }
                  placeholder="Informe o Cargo"
                  search
                  selection
                  fluid
                  allowAdditions
                  value={data.job}
                  onAddItem={handleAddition}
                  onChange={(e, { value }) => {
                    setData({ ...data, job: value, text: value });
                  }}
                />
              ) : (
                <Form.Input
                  placeholder="Cargo"
                  onChange={(e, { value }) => setData({ ...data, job: value })}
                  autoFocus
                />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Field>
              <label>Skills</label>
              <Dropdown
                search
                placeholder="Skills"
                multiple
                fluid
                selection
                onAddItem={handleAddItem}
                allowAdditions
                value={data.skills}
                options={
                  data.category !== "Tecnologia"
                    ? admSkillsOptions
                    : tecSkillsOptions
                }
                onChange={handleChangeOpt}
              />

              {/* <Form.Input
                search
                placeholder="Skills"
                multiple
                fluid
                selection
                onAddItem={handleAddItem}
                allowAdditions
                value={data.skills}
                options={data.category !== "Tecnologia" ? admSkillsOptions : tecSkillsOptions}
                onChange={handleChangeOpt}
              /> */}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form.TextArea
              label="Observação"
              placeholder="Observação"
              name="info"
              onChange={handleChange}
              value={info}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form.Input
              width={5}
              label="Upload"
              type="file"
              accept="application/pdf"
              onChange={(e) => setFile(e.target.files[0])}
            />
            {!id ? (
              <Button
                inverted
                color="orange"
                type="submit"
                disabled={progress !== null && progress < 100}
              >
                Adicionar
              </Button>
            ) : (
              <Button
                inverted
                color="orange"
                type="submit"
                disabled={progress !== null && progress < 100}
              >
                Atualizar
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AddEditUser;
