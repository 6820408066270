import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import logo from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import Spinner from "../components/Spinner";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);
      await signIn(email, password);
      navigate("/home");
    } catch (e) {
      setLoading(false);
      setError(e.message);
      
      console.log(e.message);
      
    }
  };
  
  return (
    <>
    
    <Grid textAlign="center" style={{ height: "100vh", backgroundColor: "#1B1C1D" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="orange" textAlign="center">
          <Image src={logo} /> Portal WiseHunter Recruiter
        </Header>
        <Form size="large" onSubmit={handleSubmit}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Senha"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />


            {loading ? (
              <Spinner />
            ) : (
              <Button color="orange" fluid size="large">
                Entrar
                
              </Button>           
            )}
          </Segment>
        </Form>
        <Message>
          <a href='#'>Esqueceu a Senha?</a>
        </Message>
      </Grid.Column>
    </Grid>
    </>
  );
};

export default Signin;
